import React, { useState } from "react";
import NutritionData from "data/NutritionData";
import "./Calculator.css";

const NutritionCalculator = () => {
  const [inputs, setInputs] = useState([
    { selectedType: "", selectedItem: "", grams: "", nutritionData: {} },
  ]);

  const handleTypeChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index].selectedType = value;
    newInputs[index].selectedItem = ""; // Reset selected item when type changes
    setInputs(newInputs);
  };

  const handleItemChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index].selectedItem = value;
    setInputs(newInputs);

    calculateNutrition(index); // Calculate nutrition when item changes
  };

  const handleGramsChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index].grams = value;
    setInputs(newInputs);

    calculateNutrition(index); // Calculate nutrition when grams change
  };

  const calculateNutrition = (index) => {
    const { selectedItem, grams } = inputs[index];
    const item = NutritionData.find(
      (item) =>
        item["Ingredients"] === selectedItem &&
        item["IngredientType"] === inputs[index].selectedType
    );
    if (item) {
      const nutrition = {};
      for (const key in item) {
        if (
          key !== "Ingredients" &&
          key !== "Weight" &&
          key !== "IngredientType"
        ) {
          nutrition[key] = parseFloat(item[key]) * parseFloat(grams); // Adjust for grams
        }
      }
      const newInputs = [...inputs];
      newInputs[index].nutritionData = nutrition;
      setInputs(newInputs);
    }
  };

  const getTotalNutrition = () => {
    const totalNutrition = {};
    inputs.forEach((input) => {
      const { nutritionData } = input;
      for (const key in nutritionData) {
        if (key in totalNutrition) {
          totalNutrition[key] += nutritionData[key];
        } else {
          totalNutrition[key] = nutritionData[key];
        }
      }
    });
    return totalNutrition;
  };

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      { selectedType: "", selectedItem: "", grams: "", nutritionData: {} },
    ]);
  };

  const handleRemoveInput = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };

  const totalNutrition = getTotalNutrition();

  return (
    <div>
      <h2>Nutrition Calculator</h2>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Item</th>
            <th>Grams</th>
            <th>Remove</th>
            {Object.keys(totalNutrition).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {inputs.map((input, index) => (
            <tr key={index}>
              <td>
                <select
                  value={input.selectedType}
                  onChange={(e) => handleTypeChange(index, e.target.value)}
                >
                  <option value="">Select type</option>
                  <option value="Grains">Grains</option>
                  <option value="Fruits">Fruits</option>
                  <option value="Vegetables Boiled">Vegetables Boiled</option>
                  <option value="Legumes and Beans">Legumes and Beans</option>
                  <option value="Nuts and Seeds">Nuts and Seeds</option>
                  <option value="Non Veg">Non Veg</option>
                  <option value="Oils">Oils</option>
                  <option value="Gravies">Gravies</option>
                  <option value="Dairy">Dairy</option>
                </select>
              </td>
              
              <td>
                <select
                  value={input.selectedItem}
                  onChange={(e) => handleItemChange(index, e.target.value)}
                >
                  <option value="">Select an item</option>
                  {NutritionData.filter(item => item.IngredientType === input.selectedType).map((item, idx) => (
                    <option key={idx} value={item.Ingredients}>
                      {item.Ingredients}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={input.grams}
                  onChange={(e) => handleGramsChange(index, e.target.value)}
                />
              </td>
              <td>
                <button onClick={() => handleRemoveInput(index)}>Remove</button>
              </td>
              {Object.keys(totalNutrition).map((key) => (
                <td key={key}>
                  {input.nutritionData[key]
                    ? input.nutritionData[key].toFixed(2)
                    : ""}
                </td>
              ))}
              
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddInput}>Add Input</button>
      <h3>Total Nutrition</h3>
      <ul>
        {Object.entries(totalNutrition).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {value.toFixed(2)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NutritionCalculator;
