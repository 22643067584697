const NutritionData = [
    {
        "Ingredients": "Apple",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0026",
        "Carbs": "0.1381",
        "Fat": "0.0017",
        "Fiber": "0.024",
        "Zinc": "0.0005",
        "Calcium": "0.06",
        "Iron": "0.0012",
        "A (mcg)": "0",
        "B6 (mg)": "0.00041",
        "B12": "0",
        "D": "0",
        "C": "0.087",
        "Potassium": "1.07",
        "Magnesium": "0.05",
        "Copper": "0.00046",
        "Calories": "0.52"
    },
    {
        "Ingredients": "Banana",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0109",
        "Carbs": "0.2284",
        "Fat": "0.0033",
        "Fiber": "0.026",
        "Zinc": "0.0015",
        "Calcium": "0.05",
        "Iron": "0.0031",
        "A (mcg)": "0",
        "B6 (mg)": "0.00367",
        "B12": "0",
        "D": "0",
        "C": "0.087",
        "Potassium": "3.58",
        "Magnesium": "0.27",
        "Copper": "0.00078",
        "Calories": "0.89"
    },
    {
        "Ingredients": "Papaya",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0047",
        "Carbs": "0.1139",
        "Fat": "0.0026",
        "Fiber": "0.017",
        "Zinc": "0.0008",
        "Calcium": "0.2",
        "Iron": "0.0025",
        "A (mcg)": "0",
        "B6 (mg)": "0.0006",
        "B12": "0",
        "D": "0",
        "C": "0.609",
        "Potassium": "1.82",
        "Magnesium": "0.21",
        "Copper": "0.00041",
        "Calories": "0.43"
    },
    {
        "Ingredients": "Pineapple",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0054",
        "Carbs": "0.1312",
        "Fat": "0.0012",
        "Fiber": "0.014",
        "Zinc": "0.001",
        "Calcium": "0.13",
        "Iron": "0.0029",
        "A (mcg)": "0",
        "B6 (mg)": "0.00112",
        "B12": "0",
        "D": "0",
        "C": "0.478",
        "Potassium": "1.09",
        "Magnesium": "0.12",
        "Copper": "0.00104",
        "Calories": "0.5"
    },
    {
        "Ingredients": "Musk Melon",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0084",
        "Carbs": "0.0816",
        "Fat": "0.0019",
        "Fiber": "0.008",
        "Zinc": "0.0012",
        "Calcium": "0.09",
        "Iron": "0.0021",
        "A (mcg)": "0",
        "B6 (mg)": "0.00072",
        "B12": "0",
        "D": "0",
        "C": "0.367",
        "Potassium": "2.67",
        "Magnesium": "0.12",
        "Copper": "0.00041",
        "Calories": "0.34"
    },
    {
        "Ingredients": "Orange",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0094",
        "Carbs": "0.0819",
        "Fat": "0.0014",
        "Fiber": "0.024",
        "Zinc": "0.0007",
        "Calcium": "0.4",
        "Iron": "0.001",
        "A (mcg)": "0",
        "B6 (mg)": "0.0006",
        "B12": "0",
        "D": "0",
        "C": "0.532",
        "Potassium": "1.81",
        "Magnesium": "0.1",
        "Copper": "0.00025",
        "Calories": "0.43"
    },
    {
        "Ingredients": "Kiwi",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0114",
        "Carbs": "0.1466",
        "Fat": "0.0052",
        "Fiber": "0.03",
        "Zinc": "0.001",
        "Calcium": "0.34",
        "Iron": "0.0031",
        "A (mcg)": "0",
        "B6 (mg)": "0.00149",
        "B12": "0",
        "D": "0",
        "C": "0.927",
        "Potassium": "3.12",
        "Magnesium": "0.17",
        "Copper": "0.00098",
        "Calories": "0.61"
    },
    {
        "Ingredients": "Watermelon",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0061",
        "Carbs": "0.0755",
        "Fat": "0.0015",
        "Fiber": "0.004",
        "Zinc": "0.001",
        "Calcium": "0.1",
        "Iron": "0.0024",
        "A (mcg)": "0",
        "B6 (mg)": "0.00045",
        "B12": "0",
        "D": "0",
        "C": "0.081",
        "Potassium": "1.12",
        "Magnesium": "0.1",
        "Copper": "0.00042",
        "Calories": "0.3"
    },
    {
        "Ingredients": "Mango",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0082",
        "Carbs": "0.17",
        "Fat": "0.0038",
        "Fiber": "0.016",
        "Zinc": "0.0008",
        "Calcium": "0.11",
        "Iron": "0.0016",
        "A (mcg)": "0",
        "B6 (mg)": "0.00119",
        "B12": "0",
        "D": "0",
        "C": "0.364",
        "Potassium": "1.68",
        "Magnesium": "0.1",
        "Copper": "0.00028",
        "Calories": "0.6"
    },
    {
        "Ingredients": "Grapes",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0072",
        "Carbs": "0.181",
        "Fat": "0.0016",
        "Fiber": "0.009",
        "Zinc": "0.0007",
        "Calcium": "0.1",
        "Iron": "0.0036",
        "A (mcg)": "0",
        "B6 (mg)": "0.00086",
        "B12": "0",
        "D": "0",
        "C": "0.108",
        "Potassium": "1.91",
        "Magnesium": "0.11",
        "Copper": "0.00054",
        "Calories": "0.69"
    },
    {
        "Ingredients": "Pomegranate",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0167",
        "Carbs": "0.187",
        "Fat": "0.003",
        "Fiber": "0.04",
        "Zinc": "0.0035",
        "Calcium": "0.1",
        "Iron": "0.003",
        "A (mcg)": "0",
        "B6 (mg)": "0.00075",
        "B12": "0",
        "D": "0",
        "C": "0.102",
        "Potassium": "2.36",
        "Magnesium": "0.12",
        "Copper": "0.00075",
        "Calories": "0.83"
    },
    {
        "Ingredients": "Amla",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.005",
        "Carbs": "0.147",
        "Fat": "0.001",
        "Fiber": "0.043",
        "Zinc": "0.0012",
        "Calcium": "0.12",
        "Iron": "0.002",
        "A (mcg)": "0.04",
        "B6 (mg)": "0.0003",
        "B12": "0",
        "D": "0",
        "C": "6",
        "Potassium": "1.98",
        "Magnesium": "0.06",
        "Copper": "0.001",
        "Calories": "0.44"
    },
    {
        "Ingredients": "Dragon Fruit",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.008",
        "Carbs": "0.09",
        "Fat": "0.006",
        "Fiber": "0.019",
        "Zinc": "0.001",
        "Calcium": "0.06",
        "Iron": "0.0065",
        "A (mcg)": "0",
        "B6 (mg)": "0.00045",
        "B12": "0",
        "D": "0",
        "C": "0.09",
        "Potassium": "2.87",
        "Magnesium": "0.6",
        "Copper": "0.00043",
        "Calories": "0.6"
    },
    {
        "Ingredients": "Green Grapes",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0072",
        "Carbs": "0.175",
        "Fat": "0.0016",
        "Fiber": "0.009",
        "Zinc": "0.0007",
        "Calcium": "0.1",
        "Iron": "0.0036",
        "A (mcg)": "0",
        "B6 (mg)": "0.00086",
        "B12": "0",
        "D": "0",
        "C": "0.032",
        "Potassium": "1.91",
        "Magnesium": "0.11",
        "Copper": "0.00073",
        "Calories": "0.67"
    },
    {
        "Ingredients": "Black Grapes",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0072",
        "Carbs": "0.175",
        "Fat": "0.0016",
        "Fiber": "0.009",
        "Zinc": "0.0007",
        "Calcium": "0.1",
        "Iron": "0.0036",
        "A (mcg)": "0",
        "B6 (mg)": "0.00086",
        "B12": "0",
        "D": "0",
        "C": "0.032",
        "Potassium": "1.91",
        "Magnesium": "0.11",
        "Copper": "0.00073",
        "Calories": "0.67"
    },
    {
        "Ingredients": "Strawberry",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0067",
        "Carbs": "0.0768",
        "Fat": "0.003",
        "Fiber": "0.02",
        "Zinc": "0.0014",
        "Calcium": "0.16",
        "Iron": "0.0041",
        "A (mcg)": "0.12",
        "B6 (mg)": "0.00047",
        "B12": "0",
        "D": "0",
        "C": "0.588",
        "Potassium": "1.53",
        "Magnesium": "0.13",
        "Copper": "0.00035",
        "Calories": "0.32"
    },
    {
        "Ingredients": "Avocado",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.02",
        "Carbs": "0.0853",
        "Fat": "0.1466",
        "Fiber": "0.067",
        "Zinc": "0.0129",
        "Calcium": "0.12",
        "Iron": "0.0055",
        "A (mcg)": "0.07",
        "B6 (mg)": "0.00257",
        "B12": "0",
        "D": "0",
        "C": "0.146",
        "Potassium": "4.85",
        "Magnesium": "0.29",
        "Copper": "0.00085",
        "Calories": "1.6"
    },
    {
        "Ingredients": "Grapefruit",
        "IngredientType": "Fruits",
        "Weight": "1",
        "Protein": "0.0063",
        "Carbs": "0.0841",
        "Fat": "0.001",
        "Fiber": "0.016",
        "Zinc": "0.0005",
        "Calcium": "0.12",
        "Iron": "0.0008",
        "A (mcg)": "0.05",
        "B6 (mg)": "0.0004",
        "B12": "0",
        "D": "0",
        "C": "0.312",
        "Potassium": "1.35",
        "Magnesium": "0.08",
        "Copper": "0.00033",
        "Calories": "0.32"
    },
    {
        "Ingredients": "Little Millet (Cooked)",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.035",
        "Carbs": "0.23",
        "Fat": "0.01",
        "Fiber": "0.015",
        "Zinc": "0.006",
        "Calcium": "0.08",
        "Iron": "0.005",
        "A (mcg)": "0.01",
        "B6 (mg)": "0.001",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.8",
        "Magnesium": "0.25",
        "Copper": "0.001",
        "Calories": "1.19"
    },
    {
        "Ingredients": "Kodo Millet (Cooked)",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.035",
        "Carbs": "0.21",
        "Fat": "0.01",
        "Fiber": "0.012",
        "Zinc": "0.004",
        "Calcium": "0.08",
        "Iron": "0.005",
        "A (mcg)": "0",
        "B6 (mg)": "0.001",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.36",
        "Magnesium": "0.25",
        "Copper": "0.001",
        "Calories": "1"
    },
    {
        "Ingredients": "White Rice- Cooked",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.0236",
        "Carbs": "0.2817",
        "Fat": "0.0028",
        "Fiber": "0.004",
        "Zinc": "0.0022",
        "Calcium": "0.1",
        "Iron": "0.0041",
        "A (mcg)": "0",
        "B6 (mg)": "0.00019",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.35",
        "Magnesium": "0.06",
        "Copper": "0.0002",
        "Calories": "1.3"
    },
    {
        "Ingredients": "Brown Rice- Cooked",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.0268",
        "Carbs": "0.2352",
        "Fat": "0.0077",
        "Fiber": "0.016",
        "Zinc": "0.0124",
        "Calcium": "0.12",
        "Iron": "0.0041",
        "A (mcg)": "0",
        "B6 (mg)": "0.0005",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.43",
        "Magnesium": "0.15",
        "Copper": "0.0021",
        "Calories": "1.11"
    },
    {
        "Ingredients": "Foxtail Millet- Cooked ",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.0422",
        "Carbs": "0.2296",
        "Fat": "0.0155",
        "Fiber": "0.035",
        "Zinc": "0.0085",
        "Calcium": "0.08",
        "Iron": "0.008",
        "A (mcg)": "0",
        "B6 (mg)": "0.001",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.27",
        "Magnesium": "0.24",
        "Copper": "0.0029",
        "Calories": "1.29"
    },
    {
        "Ingredients": "Barnyard Millet-Cooked ",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.0422",
        "Carbs": "0.2296",
        "Fat": "0.0155",
        "Fiber": "0.035",
        "Zinc": "0.0085",
        "Calcium": "0.08",
        "Iron": "0.008",
        "A (mcg)": "0",
        "B6 (mg)": "0.001",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.27",
        "Magnesium": "0.24",
        "Copper": "0.0029",
        "Calories": "1.29"
    },
    {
        "Ingredients": "Oats (Rolled Oats)",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.1689",
        "Carbs": "0.6627",
        "Fat": "0.069",
        "Fiber": "0.106",
        "Zinc": "0.0494",
        "Calcium": "0.54",
        "Iron": "0.0472",
        "A (mcg)": "0",
        "B6 (mg)": "0.0116",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "4.29",
        "Magnesium": "1.77",
        "Copper": "0.0062",
        "Calories": "3.89"
    },
    {
        "Ingredients": "Chapatis",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.064",
        "Carbs": "0.21",
        "Fat": "0.037",
        "Fiber": "0.027",
        "Zinc": "0.009",
        "Calcium": "0.2",
        "Iron": "0.027",
        "A (mcg)": "0.06",
        "B6 (mg)": "0.002",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.9",
        "Magnesium": "0.25",
        "Copper": "0.002",
        "Calories": "1.2"
    },
    {
        "Ingredients": "Barley (Cooked)",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.018",
        "Carbs": "0.23",
        "Fat": "0.004",
        "Fiber": "0.032",
        "Zinc": "0.003",
        "Calcium": "0.11",
        "Iron": "0.0047",
        "A (mcg)": "0",
        "B6 (mg)": "0.0003",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.49",
        "Magnesium": "0.13",
        "Copper": "0.0007",
        "Calories": "1.22"
    },
    {
        "Ingredients": "Sorghum (Cooked)",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.033",
        "Carbs": "0.17",
        "Fat": "0.006",
        "Fiber": "0.009",
        "Zinc": "0.008",
        "Calcium": "0.05",
        "Iron": "0.006",
        "A (mcg)": "0",
        "B6 (mg)": "0.0006",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "1.02",
        "Magnesium": "0.22",
        "Copper": "0.0013",
        "Calories": "0.99"
    },
    {
        "Ingredients": "Finger Millet (Cooked)",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.013",
        "Carbs": "0.171",
        "Fat": "0.002",
        "Fiber": "0.003",
        "Zinc": "0.004",
        "Calcium": "0.13",
        "Iron": "0.0034",
        "A (mcg)": "0",
        "B6 (mg)": "0.0003",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.42",
        "Magnesium": "0.13",
        "Copper": "0.0004",
        "Calories": "0.71"
    },
    {
        "Ingredients": "Pearl Millet (Cooked)",
        "IngredientType": "Grains",
        "Weight": "1",
        "Protein": "0.015",
        "Carbs": "0.2",
        "Fat": "0.001",
        "Fiber": "0.009",
        "Zinc": "0.004",
        "Calcium": "0.1",
        "Iron": "0.0012",
        "A (mcg)": "0",
        "B6 (mg)": "0.0003",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0.36",
        "Magnesium": "0.16",
        "Copper": "0.0006",
        "Calories": "0.92"
    },
    {
        "Ingredients": "Moong Dal-Cooked ",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.0702",
        "Carbs": "0.1915",
        "Fat": "0.0138",
        "Fiber": "0.018",
        "Zinc": "0.0138",
        "Calcium": "0.13",
        "Iron": "0.014",
        "A (mcg)": "0",
        "B6 (mg)": "0.00139",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "1.24",
        "Magnesium": "0.21",
        "Copper": "0.00062",
        "Calories": "1.05"
    },
    {
        "Ingredients": "Toor Dal- Cooked ",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.0689",
        "Carbs": "0.2084",
        "Fat": "0.0078",
        "Fiber": "0.037",
        "Zinc": "0.0069",
        "Calcium": "0.27",
        "Iron": "0.0149",
        "A (mcg)": "0",
        "B6 (mg)": "0.00139",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "1.07",
        "Magnesium": "0.34",
        "Copper": "0.00139",
        "Calories": "1.09"
    },
    {
        "Ingredients": "Rajma Beans-Boiled ",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.0886",
        "Carbs": "0.228",
        "Fat": "0.0039",
        "Fiber": "0.084",
        "Zinc": "0.0096",
        "Calcium": "0.28",
        "Iron": "0.0249",
        "A (mcg)": "0",
        "B6 (mg)": "0.00143",
        "B12": "0",
        "D": "0",
        "C": "0.011",
        "Potassium": "3.3",
        "Magnesium": "0.36",
        "Copper": "0.00278",
        "Calories": "1.27"
    },
    {
        "Ingredients": "Soya Beans-Boiled ",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.1235",
        "Carbs": "0.0733",
        "Fat": "0.068",
        "Fiber": "0.035",
        "Zinc": "0.009",
        "Calcium": "1.3",
        "Iron": "0.0248",
        "A (mcg)": "0",
        "B6 (mg)": "0.0028",
        "B12": "0",
        "D": "0",
        "C": "0.096",
        "Potassium": "5.15",
        "Magnesium": "1.83",
        "Copper": "0.00267",
        "Calories": "1.41"
    },
    {
        "Ingredients": "Cow Peas-Boiled ",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.0829",
        "Carbs": "0.206",
        "Fat": "0.012",
        "Fiber": "0.056",
        "Zinc": "0.009",
        "Calcium": "0.37",
        "Iron": "0.031",
        "A (mcg)": "0",
        "B6 (mg)": "0.0023",
        "B12": "0",
        "D": "0",
        "C": "0.045",
        "Potassium": "2.29",
        "Magnesium": "0.91",
        "Copper": "0.00423",
        "Calories": "1.2"
    },
    {
        "Ingredients": "Peanuts-Boiled ",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.135",
        "Carbs": "0.1613",
        "Fat": "0.248",
        "Fiber": "0.085",
        "Zinc": "0.015",
        "Calcium": "0.55",
        "Iron": "0.024",
        "A (mcg)": "0.01",
        "B6 (mg)": "0.00348",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "1.8",
        "Magnesium": "1.02",
        "Copper": "0",
        "Calories": "3.18"
    },
    {
        "Ingredients": "Chickpeas-Boiled",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.0886",
        "Carbs": "0.2742",
        "Fat": "0.0259",
        "Fiber": "0.076",
        "Zinc": "0.0153",
        "Calcium": "0.49",
        "Iron": "0.0289",
        "A (mcg)": "0.2",
        "B6 (mg)": "0.0053",
        "B12": "0",
        "D": "0",
        "C": "0.047",
        "Potassium": "2.91",
        "Magnesium": "0.48",
        "Copper": "0.0084",
        "Calories": "1.64"
    },
    {
        "Ingredients": "Chana Dal- Boiled",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.0886",
        "Carbs": "0.2742",
        "Fat": "0.0259",
        "Fiber": "0.076",
        "Zinc": "0.0153",
        "Calcium": "0.49",
        "Iron": "0.0289",
        "A (mcg)": "0.2",
        "B6 (mg)": "0.0053",
        "B12": "0",
        "D": "0",
        "C": "0.047",
        "Potassium": "2.91",
        "Magnesium": "0.48",
        "Copper": "0.0084",
        "Calories": "1.64"
    },
    {
        "Ingredients": "Chana Dal- Roasted",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.2243",
        "Carbs": "0.6043",
        "Fat": "0.053",
        "Fiber": "0.156",
        "Zinc": "0.0306",
        "Calcium": "1",
        "Iron": "0.0578",
        "A (mcg)": "0.4",
        "B6 (mg)": "0.0106",
        "B12": "0",
        "D": "0",
        "C": "0.094",
        "Potassium": "5.83",
        "Magnesium": "0.97",
        "Copper": "0.0168",
        "Calories": "3.52"
    },
    {
        "Ingredients": "Black Beans (Boiled)",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.09",
        "Carbs": "0.2",
        "Fat": "0.005",
        "Fiber": "0.087",
        "Zinc": "0.01",
        "Calcium": "0.28",
        "Iron": "0.036",
        "A (mcg)": "0",
        "B6 (mg)": "0.0021",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "3.39",
        "Magnesium": "1.71",
        "Copper": "0.003",
        "Calories": "1.32"
    },
    {
        "Ingredients": "Brown Chana (Boiled)",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.09",
        "Carbs": "0.27",
        "Fat": "0.033",
        "Fiber": "0.083",
        "Zinc": "0.015",
        "Calcium": "0.49",
        "Iron": "0.026",
        "A (mcg)": "0",
        "B6 (mg)": "0.0012",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "4.9",
        "Magnesium": "0",
        "Copper": "0.001",
        "Calories": "1.64"
    },
    {
        "Ingredients": "Lima Beans (Boiled)",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.087",
        "Carbs": "0.197",
        "Fat": "0.004",
        "Fiber": "0.07",
        "Zinc": "0.012",
        "Calcium": "0.24",
        "Iron": "0.024",
        "A (mcg)": "0",
        "B6 (mg)": "0.0021",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "5.08",
        "Magnesium": "0.81",
        "Copper": "0.001",
        "Calories": "1.15"
    },
    {
        "Ingredients": "Pinto Beans (Boiled)",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.09",
        "Carbs": "0.21",
        "Fat": "0.005",
        "Fiber": "0.077",
        "Zinc": "0.011",
        "Calcium": "0.44",
        "Iron": "0.036",
        "A (mcg)": "0",
        "B6 (mg)": "0.0015",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "4.36",
        "Magnesium": "0.49",
        "Copper": "0.003",
        "Calories": "1.43"
    },
    {
        "Ingredients": "Red Kidney Beans (Boiled)",
        "IngredientType": "Legumes and Beans",
        "Weight": "1",
        "Protein": "0.09",
        "Carbs": "0.22",
        "Fat": "0.005",
        "Fiber": "0.06",
        "Zinc": "0.013",
        "Calcium": "0.35",
        "Iron": "0.026",
        "A (mcg)": "0",
        "B6 (mg)": "0.0007",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "3.69",
        "Magnesium": "0",
        "Copper": "0",
        "Calories": "0"
    },
    {
        "Ingredients": "Boiled Chicken",
        "IngredientType": "Non Veg",
        "Weight": "1",
        "Protein": "0.231",
        "Carbs": "0",
        "Fat": "0.031",
        "Fiber": "0",
        "Zinc": "0.008",
        "Calcium": "0.11",
        "Iron": "0.009",
        "A (mcg)": "0.00025",
        "B6 (mg)": "0.001",
        "B12": "0.00003",
        "D": "0",
        "C": "0",
        "Potassium": "2.56",
        "Magnesium": "0.22",
        "Copper": "0.0003",
        "Calories": "1.39"
    },
    {
        "Ingredients": "Boiled Eggs",
        "IngredientType": "Non Veg",
        "Weight": "1",
        "Protein": "0.13",
        "Carbs": "0.011",
        "Fat": "0.111",
        "Fiber": "0",
        "Zinc": "0.013",
        "Calcium": "0.56",
        "Iron": "0.012",
        "A (mcg)": "0.0047",
        "B6 (mg)": "0.001",
        "B12": "0.00562",
        "D": "0.00875",
        "C": "0.00013",
        "Potassium": "1.26",
        "Magnesium": "0.1",
        "Copper": "0.00015",
        "Calories": "1.55"
    },
    {
        "Ingredients": "Egg Omelet",
        "IngredientType": "Non Veg",
        "Weight": "1",
        "Protein": "0.1",
        "Carbs": "0.006",
        "Fat": "0.118",
        "Fiber": "0",
        "Zinc": "0.005",
        "Calcium": "0.52",
        "Iron": "0.017",
        "A (mcg)": "0.0069",
        "B6 (mg)": "0.0012",
        "B12": "0.00562",
        "D": "0.00875",
        "C": "0.00013",
        "Potassium": "1.16",
        "Magnesium": "0.08",
        "Copper": "0.00014",
        "Calories": "1.54"
    },
    {
        "Ingredients": "Egg Bhurji",
        "IngredientType": "Non Veg",
        "Weight": "1",
        "Protein": "0.11",
        "Carbs": "0.01",
        "Fat": "0.13",
        "Fiber": "0",
        "Zinc": "0.006",
        "Calcium": "0.58",
        "Iron": "0.015",
        "A (mcg)": "0.0061",
        "B6 (mg)": "0.0014",
        "B12": "0.00562",
        "D": "0.00875",
        "C": "0.00013",
        "Potassium": "1.21",
        "Magnesium": "0.08",
        "Copper": "0.00014",
        "Calories": "1.67"
    },
    {
        "Ingredients": "Prawns",
        "IngredientType": "Non Veg",
        "Weight": "1",
        "Protein": "0.2",
        "Carbs": "0",
        "Fat": "0.017",
        "Fiber": "0",
        "Zinc": "0.014",
        "Calcium": "0.34",
        "Iron": "0.036",
        "A (mcg)": "0.00012",
        "B6 (mg)": "0.00153",
        "B12": "0.00032",
        "D": "0.00017",
        "C": "0.00009",
        "Potassium": "2.74",
        "Magnesium": "0.53",
        "Copper": "0.00065",
        "Calories": "0.99"
    },
    {
        "Ingredients": "Boneless Basa Fish",
        "IngredientType": "Non Veg",
        "Weight": "1",
        "Protein": "0.16",
        "Carbs": "0",
        "Fat": "0.02",
        "Fiber": "0",
        "Zinc": "0.006",
        "Calcium": "0.17",
        "Iron": "0.005",
        "A (mcg)": "0.00068",
        "B6 (mg)": "0.00198",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "3.7",
        "Magnesium": "0.23",
        "Copper": "0.00023",
        "Calories": "0.88"
    },
    {
        "Ingredients": "Boiled Mutton",
        "IngredientType": "Non Veg",
        "Weight": "1",
        "Protein": "0.256",
        "Carbs": "0",
        "Fat": "0.202",
        "Fiber": "0",
        "Zinc": "0.031",
        "Calcium": "0.11",
        "Iron": "0.021",
        "A (mcg)": "0.00014",
        "B6 (mg)": "0.00129",
        "B12": "0.00003",
        "D": "0.0002",
        "C": "0",
        "Potassium": "2.59",
        "Magnesium": "0.17",
        "Copper": "0.00015",
        "Calories": "2.97"
    },
    {
        "Ingredients": "Boiled Chicken Boneless",
        "IngredientType": "Non Veg",
        "Weight": "1",
        "Protein": "0.3102",
        "Carbs": "0",
        "Fat": "0.036",
        "Fiber": "0",
        "Zinc": "0.0058",
        "Calcium": "0.08",
        "Iron": "0.012",
        "A (mcg)": "0",
        "B6 (mg)": "0.0007",
        "B12": "0.0045",
        "D": "0",
        "C": "0",
        "Potassium": "2.23",
        "Magnesium": "0.21",
        "Copper": "0.0006",
        "Calories": "1.65"
    },
    {
        "Ingredients": "Cashew ",
        "IngredientType": "Nuts and Seeds",
        "Weight": "1",
        "Protein": "0.182",
        "Carbs": "0.3019",
        "Fat": "0.4385",
        "Fiber": "0.033",
        "Zinc": "0.0578",
        "Calcium": "0.37",
        "Iron": "0.0668",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "6.6",
        "Magnesium": "2.92",
        "Copper": "0.0222",
        "Calories": "5.6"
    },
    {
        "Ingredients": "Pista ",
        "IngredientType": "Nuts and Seeds",
        "Weight": "1",
        "Protein": "0.2016",
        "Carbs": "0.2717",
        "Fat": "0.4532",
        "Fiber": "0.103",
        "Zinc": "0.022",
        "Calcium": "1.07",
        "Iron": "0.0392",
        "A (mcg)": "0.29",
        "B6 (mg)": "0.017",
        "B12": "0",
        "D": "0",
        "C": "0.056",
        "Potassium": "10.25",
        "Magnesium": "1.21",
        "Copper": "0.013",
        "Calories": "5.62"
    },
    {
        "Ingredients": "Badam",
        "IngredientType": "Nuts and Seeds",
        "Weight": "1",
        "Protein": "0.2115",
        "Carbs": "0.2155",
        "Fat": "0.4993",
        "Fiber": "0.125",
        "Zinc": "0.0308",
        "Calcium": "2.69",
        "Iron": "0.0372",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "7.33",
        "Magnesium": "2.7",
        "Copper": "0.00996",
        "Calories": "5.79"
    },
    {
        "Ingredients": "Sesame Seeds",
        "IngredientType": "Nuts and Seeds",
        "Weight": "1",
        "Protein": "0.1773",
        "Carbs": "0.2345",
        "Fat": "0.4967",
        "Fiber": "0.118",
        "Zinc": "0.0775",
        "Calcium": "9.75",
        "Iron": "0.1455",
        "A (mcg)": "0",
        "B6 (mg)": "0.0079",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "7.05",
        "Magnesium": "3.56",
        "Copper": "0.04082",
        "Calories": "5.65"
    },
    {
        "Ingredients": "Pumpkin Seeds",
        "IngredientType": "Nuts and Seeds",
        "Weight": "1",
        "Protein": "0.2984",
        "Carbs": "0.1071",
        "Fat": "0.4905",
        "Fiber": "0.06",
        "Zinc": "0.0764",
        "Calcium": "0.46",
        "Iron": "0.0882",
        "A (mcg)": "0",
        "B6 (mg)": "0.00143",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "9.19",
        "Magnesium": "5.35",
        "Copper": "0.01343",
        "Calories": "5.74"
    },
    {
        "Ingredients": "Pistachios",
        "IngredientType": "Nuts and Seeds",
        "Weight": "1",
        "Protein": "0.2016",
        "Carbs": "0.2717",
        "Fat": "0.4532",
        "Fiber": "0.103",
        "Zinc": "0.022",
        "Calcium": "1.07",
        "Iron": "0.0392",
        "A (mcg)": "2.9",
        "B6 (mg)": "0.017",
        "B12": "0",
        "D": "0",
        "C": "0.56",
        "Potassium": "10.25",
        "Magnesium": "1.21",
        "Copper": "0.013",
        "Calories": "5.62"
    },
    {
        "Ingredients": "Dates",
        "IngredientType": "Nuts and Seeds",
        "Weight": "1",
        "Protein": "0.0181",
        "Carbs": "0.7497",
        "Fat": "0.0015",
        "Fiber": "0.067",
        "Zinc": "0.0022",
        "Calcium": "1.1",
        "Iron": "0.0039",
        "A (mcg)": "0",
        "B6 (mg)": "0.0017",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "6.96",
        "Magnesium": "0.65",
        "Copper": "0.0013",
        "Calories": "2.82"
    },
    {
        "Ingredients": "Jaggery",
        "IngredientType": "Nuts and Seeds",
        "Weight": "1",
        "Protein": "0.002",
        "Carbs": "0.859",
        "Fat": "0.001",
        "Fiber": "0",
        "Zinc": "0.0003",
        "Calcium": "0",
        "Iron": "0.004",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0.0001",
        "Calories": "3.83"
    },
    {
        "Ingredients": "Palm Oil",
        "IngredientType": "Oils",
        "Weight": "1",
        "Protein": "0",
        "Carbs": "0",
        "Fat": "1",
        "Fiber": "0",
        "Zinc": "0",
        "Calcium": "0",
        "Iron": "0",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0",
        "Calories": "9"
    },
    {
        "Ingredients": "Sunflower Oil",
        "IngredientType": "Oils",
        "Weight": "1",
        "Protein": "0",
        "Carbs": "0",
        "Fat": "1",
        "Fiber": "0",
        "Zinc": "0",
        "Calcium": "0",
        "Iron": "0",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0",
        "Calories": "8.84"
    },
    {
        "Ingredients": "Groundnut Oil",
        "IngredientType": "Oils",
        "Weight": "1",
        "Protein": "0",
        "Carbs": "0",
        "Fat": "1",
        "Fiber": "0",
        "Zinc": "0",
        "Calcium": "0",
        "Iron": "0",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0",
        "Calories": "8.84"
    },
    {
        "Ingredients": "Coconut Oil",
        "IngredientType": "Oils",
        "Weight": "1",
        "Protein": "0",
        "Carbs": "0",
        "Fat": "1",
        "Fiber": "0",
        "Zinc": "0",
        "Calcium": "0",
        "Iron": "0",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0",
        "Calories": "8.62"
    },
    {
        "Ingredients": "Ghee",
        "IngredientType": "Oils",
        "Weight": "1",
        "Protein": "0",
        "Carbs": "0",
        "Fat": "0.995",
        "Fiber": "0",
        "Zinc": "0",
        "Calcium": "0",
        "Iron": "0",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0",
        "Calories": "9"
    },
    {
        "Ingredients": "Olive Oil",
        "IngredientType": "Oils",
        "Weight": "1",
        "Protein": "0",
        "Carbs": "0",
        "Fat": "1",
        "Fiber": "0",
        "Zinc": "0",
        "Calcium": "0",
        "Iron": "0",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0",
        "Calories": "8.84"
    },
    {
        "Ingredients": "Rice Bran Oil",
        "IngredientType": "Oils",
        "Weight": "1",
        "Protein": "0",
        "Carbs": "0",
        "Fat": "1",
        "Fiber": "0",
        "Zinc": "0",
        "Calcium": "0",
        "Iron": "0",
        "A (mcg)": "0",
        "B6 (mg)": "0",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0",
        "Calories": "9"
    },
    {
        "Ingredients": "Carrots",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0066",
        "Carbs": "0.1013",
        "Fat": "0.0013",
        "Fiber": "0.021",
        "Zinc": "0.0011",
        "Calcium": "0.27",
        "Iron": "0.0033",
        "A (mcg)": "0",
        "B6 (mg)": "0.00045",
        "B12": "0",
        "D": "0",
        "C": "0.049",
        "Potassium": "2.88",
        "Magnesium": "0.12",
        "Copper": "0.0005",
        "Calories": "0.38"
    },
    {
        "Ingredients": "Green Peas",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0347",
        "Carbs": "0.1163",
        "Fat": "0.0031",
        "Fiber": "0.041",
        "Zinc": "0.0099",
        "Calcium": "0.2",
        "Iron": "0.0117",
        "A (mcg)": "0",
        "B6 (mg)": "0.00162",
        "B12": "0",
        "D": "0",
        "C": "0.2",
        "Potassium": "1.8",
        "Magnesium": "0.25",
        "Copper": "0.00197",
        "Calories": "0.62"
    },
    {
        "Ingredients": "Sweet Corn",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0308",
        "Carbs": "0.18",
        "Fat": "0.0112",
        "Fiber": "0.018",
        "Zinc": "0.008",
        "Calcium": "0.01",
        "Iron": "0.0054",
        "A (mcg)": "0",
        "B6 (mg)": "0.00084",
        "B12": "0",
        "D": "0",
        "C": "0.049",
        "Potassium": "2.3",
        "Magnesium": "0.32",
        "Copper": "0.00127",
        "Calories": "0.74"
    },
    {
        "Ingredients": "Mushrooms",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0236",
        "Carbs": "0.0247",
        "Fat": "0.0038",
        "Fiber": "0.008",
        "Zinc": "0.0126",
        "Calcium": "0.02",
        "Iron": "0.0038",
        "A (mcg)": "0",
        "B6 (mg)": "0.00079",
        "B12": "0",
        "D": "0",
        "C": "0.016",
        "Potassium": "2.4",
        "Magnesium": "0.1",
        "Copper": "0.00116",
        "Calories": "0.16"
    },
    {
        "Ingredients": "Paneer",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.182",
        "Carbs": "0.0156",
        "Fat": "0.2704",
        "Fiber": "0",
        "Zinc": "0",
        "Calcium": "1.87",
        "Iron": "0.0009",
        "A (mcg)": "0",
        "B6 (mg)": "0.0003",
        "B12": "0.0099",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0",
        "Calories": "1.95"
    },
    {
        "Ingredients": "Potato",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0136",
        "Carbs": "0.1177",
        "Fat": "0.0006",
        "Fiber": "0.0176",
        "Zinc": "0.0023",
        "Calcium": "0.09",
        "Iron": "0.0061",
        "A (mcg)": "0",
        "B6 (mg)": "0.00222",
        "B12": "0",
        "D": "0",
        "C": "0.158",
        "Potassium": "3.22",
        "Magnesium": "0.18",
        "Copper": "0.00051",
        "Calories": "0.8"
    },
    {
        "Ingredients": "Soya Chunks",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.166",
        "Carbs": "0.099",
        "Fat": "0.005",
        "Fiber": "0.05",
        "Zinc": "0.016",
        "Calcium": "3.5",
        "Iron": "0.104",
        "A (mcg)": "0",
        "B6 (mg)": "0.0015",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "5.4",
        "Magnesium": "0.8",
        "Copper": "0.002",
        "Calories": "1.44"
    },
    {
        "Ingredients": "French Beans",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0135",
        "Carbs": "0.0533",
        "Fat": "0.0015",
        "Fiber": "0.0272",
        "Zinc": "0.0019",
        "Calcium": "0.29",
        "Iron": "0.0054",
        "A (mcg)": "0",
        "B6 (mg)": "0.00059",
        "B12": "0",
        "D": "0",
        "C": "0.22",
        "Potassium": "1.53",
        "Magnesium": "0.24",
        "Copper": "0.00075",
        "Calories": "0.23"
    },
    {
        "Ingredients": "Broccoli",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0237",
        "Carbs": "0.0561",
        "Fat": "0.0034",
        "Fiber": "0.0219",
        "Zinc": "0.0035",
        "Calcium": "0.39",
        "Iron": "0.0073",
        "A (mcg)": "0",
        "B6 (mg)": "0.00175",
        "B12": "0",
        "D": "0",
        "C": "0.767",
        "Potassium": "2.7",
        "Magnesium": "0.18",
        "Copper": "0.00039",
        "Calories": "0.29"
    },
    {
        "Ingredients": "Onion",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0066",
        "Carbs": "0.0554",
        "Fat": "0.0006",
        "Fiber": "0.0099",
        "Zinc": "0.0011",
        "Calcium": "0.13",
        "Iron": "0.0017",
        "A (mcg)": "0",
        "B6 (mg)": "0.00045",
        "B12": "0",
        "D": "0",
        "C": "0.04",
        "Potassium": "0.73",
        "Magnesium": "0.05",
        "Copper": "0.0005",
        "Calories": "0.2"
    },
    {
        "Ingredients": "Tomato",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0073",
        "Carbs": "0.0316",
        "Fat": "0.0016",
        "Fiber": "0.0096",
        "Zinc": "0.0008",
        "Calcium": "0.03",
        "Iron": "0.0018",
        "A (mcg)": "0",
        "B6 (mg)": "0.00072",
        "B12": "0",
        "D": "0",
        "C": "0.079",
        "Potassium": "1.44",
        "Magnesium": "0.07",
        "Copper": "0.00071",
        "Calories": "0.09"
    },
    {
        "Ingredients": "Green Chilly",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0128",
        "Carbs": "0.026",
        "Fat": "0.0013",
        "Fiber": "0.0195",
        "Zinc": "0.0038",
        "Calcium": "0.22",
        "Iron": "0.0132",
        "A (mcg)": "0",
        "B6 (mg)": "0.00318",
        "B12": "0",
        "D": "0",
        "C": "0",
        "Potassium": "0",
        "Magnesium": "0",
        "Copper": "0.0028",
        "Calories": "0.29"
    },
    {
        "Ingredients": "Cauliflower",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.015",
        "Carbs": "0.033",
        "Fat": "0.0023",
        "Fiber": "0.015",
        "Zinc": "0.0023",
        "Calcium": "0.2",
        "Iron": "0.0045",
        "A (mcg)": "0",
        "B6 (mg)": "0.0018",
        "B12": "0",
        "D": "0",
        "C": "0.36",
        "Potassium": "2.24",
        "Magnesium": "0.11",
        "Copper": "0.0005",
        "Calories": "0.19"
    },
    {
        "Ingredients": "Capsicum",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.015",
        "Carbs": "0.033",
        "Fat": "0.0023",
        "Fiber": "0.015",
        "Zinc": "0.0023",
        "Calcium": "0.2",
        "Iron": "0.0045",
        "A (mcg)": "0",
        "B6 (mg)": "0.0018",
        "B12": "0",
        "D": "0",
        "C": "0.36",
        "Potassium": "2.24",
        "Magnesium": "0.11",
        "Copper": "0.0005",
        "Calories": "0.19"
    },
    {
        "Ingredients": "Palak",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.015",
        "Carbs": "0.019",
        "Fat": "0.002",
        "Fiber": "0.0122",
        "Zinc": "0.0045",
        "Calcium": "0.53",
        "Iron": "0.009",
        "A (mcg)": "0",
        "B6 (mg)": "0.00067",
        "B12": "0",
        "D": "0",
        "C": "0.2",
        "Potassium": "3.05",
        "Magnesium": "0.52",
        "Copper": "0.0039",
        "Calories": "0.17"
    },
    {
        "Ingredients": "Lettuce",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.007",
        "Carbs": "0.0145",
        "Fat": "0.001",
        "Fiber": "0.0065",
        "Zinc": "0.001",
        "Calcium": "0.21",
        "Iron": "0.003",
        "A (mcg)": "0",
        "B6 (mg)": "0.0005",
        "B12": "0",
        "D": "0",
        "C": "0.02",
        "Potassium": "0.59",
        "Magnesium": "0.03",
        "Copper": "0.0005",
        "Calories": "0.08"
    },
    {
        "Ingredients": "Cabbage ",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0128",
        "Carbs": "0.058",
        "Fat": "0.001",
        "Fiber": "0.025",
        "Zinc": "0.0018",
        "Calcium": "0.4",
        "Iron": "0.0047",
        "A (mcg)": "0.05",
        "B6 (mg)": "0.00124",
        "B12": "0",
        "D": "0",
        "C": "0.366",
        "Potassium": "1.7",
        "Magnesium": "0.11",
        "Copper": "0.00024",
        "Calories": "0.25"
    },
    {
        "Ingredients": "Coriander Leaves",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.021",
        "Carbs": "0.0367",
        "Fat": "0.0052",
        "Fiber": "0.028",
        "Zinc": "0.005",
        "Calcium": "0.67",
        "Iron": "0.0177",
        "A (mcg)": "3.37",
        "B6 (mg)": "0.00149",
        "B12": "0",
        "D": "0",
        "C": "0.27",
        "Potassium": "5.21",
        "Magnesium": "0.26",
        "Copper": "0.00225",
        "Calories": "0.23"
    },
    {
        "Ingredients": "Eggplant ",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.01",
        "Carbs": "0.0588",
        "Fat": "0.0018",
        "Fiber": "0.03",
        "Zinc": "0.0035",
        "Calcium": "0.15",
        "Iron": "0.0024",
        "A (mcg)": "0.23",
        "B6 (mg)": "0.00084",
        "B12": "0",
        "D": "0",
        "C": "0.022",
        "Potassium": "2.3",
        "Magnesium": "0.14",
        "Copper": "0.00081",
        "Calories": "0.35"
    },
    {
        "Ingredients": "Ladies Finger ",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.019",
        "Carbs": "0.0745",
        "Fat": "0.0019",
        "Fiber": "0.032",
        "Zinc": "0.0058",
        "Calcium": "0.77",
        "Iron": "0.0062",
        "A (mcg)": "0.36",
        "B6 (mg)": "0.00215",
        "B12": "0",
        "D": "0",
        "C": "0.232",
        "Potassium": "2.99",
        "Magnesium": "0.57",
        "Copper": "0.00094",
        "Calories": "0.33"
    },
    {
        "Ingredients": "Basil Leaves",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0315",
        "Carbs": "0.0265",
        "Fat": "0.0064",
        "Fiber": "0.016",
        "Zinc": "0.0081",
        "Calcium": "1.77",
        "Iron": "0.0317",
        "A (mcg)": "5.27",
        "B6 (mg)": "0.00155",
        "B12": "0",
        "D": "0",
        "C": "0.18",
        "Potassium": "2.95",
        "Magnesium": "0.64",
        "Copper": "0.00385",
        "Calories": "0.22"
    },
    {
        "Ingredients": "Lemon Juice",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0035",
        "Carbs": "0.084",
        "Fat": "0.002",
        "Fiber": "0.003",
        "Zinc": "0.0005",
        "Calcium": "0.06",
        "Iron": "0.001",
        "A (mcg)": "0",
        "B6 (mg)": "0.00047",
        "B12": "0",
        "D": "0",
        "C": "0.387",
        "Potassium": "1.03",
        "Magnesium": "0.06",
        "Copper": "0.00017",
        "Calories": "0.29"
    },
    {
        "Ingredients": "Sorrel Leaves",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.02",
        "Carbs": "0.035",
        "Fat": "0.005",
        "Fiber": "0.029",
        "Zinc": "0.002",
        "Calcium": "0.44",
        "Iron": "0.024",
        "A (mcg)": "0",
        "B6 (mg)": "0.00127",
        "B12": "0",
        "D": "0",
        "C": "0.489",
        "Potassium": "3.9",
        "Magnesium": "1.03",
        "Copper": "0.0013",
        "Calories": "0.29"
    },
    {
        "Ingredients": "Pumpkin (Boiled)",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.01",
        "Carbs": "0.065",
        "Fat": "0.001",
        "Fiber": "0.011",
        "Zinc": "0.0017",
        "Calcium": "0.21",
        "Iron": "0.008",
        "A (mcg)": "4.26",
        "B6 (mg)": "0.00066",
        "B12": "0",
        "D": "0",
        "C": "0.09",
        "Potassium": "2.3",
        "Magnesium": "0.12",
        "Copper": "0.00061",
        "Calories": "0.26"
    },
    {
        "Ingredients": "Bottle Gourd (Boiled)",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0062",
        "Carbs": "0.0369",
        "Fat": "0.0002",
        "Fiber": "0.012",
        "Zinc": "0.0013",
        "Calcium": "0.3",
        "Iron": "0.002",
        "A (mcg)": "0",
        "B6 (mg)": "0.00038",
        "B12": "0",
        "D": "0",
        "C": "0.101",
        "Potassium": "1.7",
        "Magnesium": "0.11",
        "Copper": "0.00034",
        "Calories": "0.14"
    },
    {
        "Ingredients": "Ridge Gourd (Boiled)",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0065",
        "Carbs": "0.042",
        "Fat": "0.001",
        "Fiber": "0.011",
        "Zinc": "0.001",
        "Calcium": "0.2",
        "Iron": "0.0036",
        "A (mcg)": "0",
        "B6 (mg)": "0.00058",
        "B12": "0",
        "D": "0",
        "C": "0.13",
        "Potassium": "1.4",
        "Magnesium": "0.08",
        "Copper": "0.00021",
        "Calories": "0.19"
    },
    {
        "Ingredients": "Bitter Gourd (Boiled)",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.01",
        "Carbs": "0.032",
        "Fat": "0.0017",
        "Fiber": "0.026",
        "Zinc": "0.008",
        "Calcium": "0.2",
        "Iron": "0.0034",
        "A (mcg)": "0",
        "B6 (mg)": "0.00044",
        "B12": "0",
        "D": "0",
        "C": "0.33",
        "Potassium": "3.19",
        "Magnesium": "0.17",
        "Copper": "0.00034",
        "Calories": "0.17"
    },
    {
        "Ingredients": "Beetroot (Boiled)",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.017",
        "Carbs": "0.0956",
        "Fat": "0.0017",
        "Fiber": "0.028",
        "Zinc": "0.0035",
        "Calcium": "0.16",
        "Iron": "0.008",
        "A (mcg)": "0.02",
        "B6 (mg)": "0.00067",
        "B12": "0",
        "D": "0",
        "C": "0.036",
        "Potassium": "3.25",
        "Magnesium": "0.23",
        "Copper": "0.00044",
        "Calories": "0.44"
    },
    {
        "Ingredients": "Ivy Gourd (Boiled)",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.011",
        "Carbs": "0.041",
        "Fat": "0.002",
        "Fiber": "0.014",
        "Zinc": "0.004",
        "Calcium": "0.24",
        "Iron": "0.006",
        "A (mcg)": "0.06",
        "B6 (mg)": "0.00049",
        "B12": "0",
        "D": "0",
        "C": "0.16",
        "Potassium": "2.85",
        "Magnesium": "0.11",
        "Copper": "0.00025",
        "Calories": "0.18"
    },
    {
        "Ingredients": "Garlic (Boiled)",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0636",
        "Carbs": "0.3307",
        "Fat": "0.005",
        "Fiber": "0.021",
        "Zinc": "0.0116",
        "Calcium": "1.81",
        "Iron": "0.012",
        "A (mcg)": "0",
        "B6 (mg)": "0.006",
        "B12": "0",
        "D": "0",
        "C": "0.312",
        "Potassium": "4.01",
        "Magnesium": "0.25",
        "Copper": "0.00299",
        "Calories": "1.49"
    },
    {
        "Ingredients": "Ginger (Boiled)",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0182",
        "Carbs": "0.1777",
        "Fat": "0.0075",
        "Fiber": "0.02",
        "Zinc": "0.0034",
        "Calcium": "0.16",
        "Iron": "0.024",
        "A (mcg)": "0",
        "B6 (mg)": "0.0016",
        "B12": "0",
        "D": "0",
        "C": "0.05",
        "Potassium": "4.15",
        "Magnesium": "0.43",
        "Copper": "0.00226",
        "Calories": "0.8"
    },
    {
        "Ingredients": "Amaranth Leaves (Boiled)",
        "IngredientType": "Vegetables Boiled",
        "Weight": "1",
        "Protein": "0.0211",
        "Carbs": "0.0402",
        "Fat": "0.003",
        "Fiber": "0.022",
        "Zinc": "0.0047",
        "Calcium": "2.15",
        "Iron": "0.0232",
        "A (mcg)": "1.46",
        "B6 (mg)": "0.0012",
        "B12": "0",
        "D": "0",
        "C": "0.64",
        "Potassium": "6.11",
        "Magnesium": "0.55",
        "Copper": "0.0016",
        "Calories": "0.23"
    },
    {
        "Ingredients": "Curd",
        "IngredientType": "Dairy",
        "Weight": "1",
        "Protein": "0.035",
        "Carbs": "0.048",
        "Fat": "0.033",
        "Fiber": "0",
        "Zinc": "0.004",
        "Calcium": "1.2",
        "Iron": "0.001",
        "A (mcg)": "0",
        "B6 (mg)": "0.001",
        "B12": "0.005",
        "D": "0.001",
        "C": "0",
        "Potassium": "1.5",
        "Magnesium": "0.1",
        "Copper": "0",
        "Calories": "0.98"
    },
    {
        "Ingredients": "Boiled Eggs",
        "IngredientType": "Dairy",
        "Weight": "1",
        "Protein": "0.13",
        "Carbs": "0.011",
        "Fat": "0.11",
        "Fiber": "0",
        "Zinc": "0.012",
        "Calcium": "0.5",
        "Iron": "0.012",
        "A (mcg)": "0.008",
        "B6 (mg)": "0.001",
        "B12": "0.015",
        "D": "0.011",
        "C": "0",
        "Potassium": "1.26",
        "Magnesium": "0.1",
        "Copper": "0",
        "Calories": "1.55"
    },
    {
        "Ingredients": "Scrambled Eggs",
        "IngredientType": "Dairy",
        "Weight": "1",
        "Protein": "0.13",
        "Carbs": "0.012",
        "Fat": "0.11",
        "Fiber": "0",
        "Zinc": "0.013",
        "Calcium": "0.56",
        "Iron": "0.018",
        "A (mcg)": "0.006",
        "B6 (mg)": "0.001",
        "B12": "0.011",
        "D": "0.012",
        "C": "0",
        "Potassium": "1.46",
        "Magnesium": "0.12",
        "Copper": "0",
        "Calories": "1.58"
    },
    {
        "Ingredients": "Milk",
        "IngredientType": "Dairy",
        "Weight": "1",
        "Protein": "0.032",
        "Carbs": "0.048",
        "Fat": "0.036",
        "Fiber": "0",
        "Zinc": "0.004",
        "Calcium": "1.13",
        "Iron": "0.001",
        "A (mcg)": "0.64",
        "B6 (mg)": "0.001",
        "B12": "0.004",
        "D": "0.012",
        "C": "0",
        "Potassium": "1.5",
        "Magnesium": "0.1",
        "Copper": "0.001",
        "Calories": "0.61"
    },
    {
        "Ingredients": "Coconut Milk",
        "IngredientType": "Dairy",
        "Weight": "1",
        "Protein": "0.0229",
        "Carbs": "0.0281",
        "Fat": "0.2384",
        "Fiber": "0.022",
        "Zinc": "0.0067",
        "Calcium": "0.16",
        "Iron": "0.033",
        "A (mcg)": "0",
        "B6 (mg)": "0.00033",
        "B12": "0",
        "D": "0",
        "C": "0.028",
        "Potassium": "2.63",
        "Magnesium": "0.37",
        "Copper": "0.00274",
        "Calories": "2.3"
    },
    {
        "Ingredients": "Fresh Cream",
        "IngredientType": "Dairy",
        "Weight": "1",
        "Protein": "0.0204",
        "Carbs": "0.0334",
        "Fat": "0.333",
        "Fiber": "0",
        "Zinc": "0.0017",
        "Calcium": "0.66",
        "Iron": "0.0007",
        "A (mcg)": "0.97",
        "B6 (mg)": "0.00033",
        "B12": "0.0034",
        "D": "0.0018",
        "C": "0.006",
        "Potassium": "1.03",
        "Magnesium": "0.09",
        "Copper": "0.00015",
        "Calories": "3.14"
    },
    {
        "Ingredients": "Tofu(Boiled)",
        "IngredientType": "Dairy",
        "Weight": "1",
        "Protein": "0.0808",
        "Carbs": "0.0178",
        "Fat": "0.048",
        "Fiber": "0.009",
        "Zinc": "0.01",
        "Calcium": "3.5",
        "Iron": "0.015",
        "A (mcg)": "0.01",
        "B6 (mg)": "0.001",
        "B12": "0",
        "D": "0",
        "C": "0.002",
        "Potassium": "1.21",
        "Magnesium": "0.3",
        "Copper": "0.0018",
        "Calories": "0.76"
    },
    {
        "Ingredients": "Onion Tomato Base Gravy",
        "IngredientType": "Gravies",
        "Weight": "1",
        "Protein": "0.0163",
        "Carbs": "0.1743",
        "Fat": "0.0776",
        "Fiber": "0.0312",
        "Zinc": "0.0021",
        "Calcium": "0.3605",
        "Iron": "0.0116",
        "A (mcg)": "0.1512",
        "B6 (mg)": "0.0016",
        "B12": "0",
        "D": "0",
        "C": "0.1246",
        "Potassium": "1.208",
        "Magnesium": "0.1966",
        "Copper": "0.0007",
        "Calories": "1.186"
    },
    {
        "Ingredients": "Yellow Base Cashew Gravy",
        "IngredientType": "Gravies",
        "Weight": "1",
        "Protein": "0.0505",
        "Carbs": "0.2739",
        "Fat": "0.2585",
        "Fiber": "0.0309",
        "Zinc": "0.0089",
        "Calcium": "0.4315",
        "Iron": "0.0246",
        "A (mcg)": "0.2562",
        "B6 (mg)": "0.0025",
        "B12": "0",
        "D": "0",
        "C": "0.1324",
        "Potassium": "2.41",
        "Magnesium": "1.0076",
        "Copper": "0.004",
        "Calories": "2.957"
    },
    {
        "Ingredients": "Green Gravy",
        "IngredientType": "Gravies",
        "Weight": "1",
        "Protein": "0.018",
        "Carbs": "0.1665",
        "Fat": "0.1022",
        "Fiber": "0.035",
        "Zinc": "0.0025",
        "Calcium": "0.5415",
        "Iron": "0.0127",
        "A (mcg)": "0.3762",
        "B6 (mg)": "0.0016",
        "B12": "0",
        "D": "0",
        "C": "0.1618",
        "Potassium": "1.582",
        "Magnesium": "0.1816",
        "Copper": "0.0007",
        "Calories": "1.4"
    },
    {
        "Ingredients": "Fresh Cream Gravy",
        "IngredientType": "Gravies",
        "Weight": "1",
        "Protein": "0.017",
        "Carbs": "0.1655",
        "Fat": "0.1424",
        "Fiber": "0.0292",
        "Zinc": "0.0023",
        "Calcium": "0.4355",
        "Iron": "0.0072",
        "A (mcg)": "0.2512",
        "B6 (mg)": "0.0016",
        "B12": "0",
        "D": "0",
        "C": "0.1598",
        "Potassium": "1.402",
        "Magnesium": "0.1646",
        "Copper": "0.0006",
        "Calories": "1.738"
    }
]

export default NutritionData;